const Calculator = function () {
    this.YEARLY_ENERGY_PRICE_INCREASE = 1.1;
    this.OBS_PERIOD = 4;
    /* limit for showing 'no-savings-text' */
    this.SAVINGS_LIMIT = 100;
    this.FORM_ERRORS = false;

    /* format numbers and / or currencies
	 * n = lenght of decimal (default 0)
	 * x = lenght of sections (default 3)
	 * curr = currency symbol at end of string
	 * pre = append / prepend curr symbol (default false, append)
	 */
    Number.prototype.format = function (n, x, curr, pre) {
        const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\.' : '$'})`;
        return (pre ? `${curr} ` : '') + (Math.round(this * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ').replaceAll(' ', ".") + (curr && !pre ? ` ${curr}` : '');
    };
};

// data object
// contains data about price and cost of different tube types by tube-length
Calculator.prototype.data = function () {
    return {
        evg: {
            name: 'EVG',
            len55: {
                watt: 28,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len60: {
                watt: 22,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len75: {
                watt: 29,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len85: {
                watt: 43,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len90: {
                watt: 35,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len97: {
                watt: 41,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len105: {
                watt: 43,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len115: {
                watt: 55,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len120: {
                watt: 41,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len145: {
                watt: 54,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len150: {
                watt: 63,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len180: {
                watt: 76,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            lifespan_h: 15000, // in h
        },
        kvg: {
            name: 'KVG',
            len55: {
                watt: "-", // no value available
                replacement_cost: "-",
                price_per_tube: "-",
            },
            len60: {
                watt: 30,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len75: {
                watt: 37,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len85: {
                watt: "-", // no value available
                replacement_cost: "-",
                price_per_tube: "-",
            },
            len90: {
                watt: 42,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len97: {
                watt: 48,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len105: {
                watt: 50,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len115: {
                watt: "-", // no value available
                replacement_cost: "-",
                price_per_tube: "-",
            },
            len120: {
                watt: 50,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len145: {
                watt: "-", // no value available
                replacement_cost: "-",
                price_per_tube: "-",
            },
            len150: {
                watt: 72,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            len180: {
                watt: 84,
                replacement_cost: 10,
                price_per_tube: 5, // eur
            },
            lifespan_h: 8000, // in h
        },
        smd: {
            name: 'SMD',
            len55: {
                watt: 7,
                replacement_cost: 10,
                price_per_tube: 33.50,
                rental_cost: 0.9, // eur
            },
            len60: {
                watt: 7,
                replacement_cost: 10,
                price_per_tube: 33.50,
                rental_cost: 0.9, // eur
            },
            len75: {
                watt: 12,
                replacement_cost: 10,
                price_per_tube: 47.50,
                rental_cost: 1.15, // eur
            },
            len85: {
                watt: 12,
                replacement_cost: 10,
                price_per_tube: 47.50,
                rental_cost: 1.15, // eur
            },
            len90: {
                watt: 12,
                replacement_cost: 10,
                price_per_tube: 47.50,
                rental_cost: 1.15, // eur
            },
            len97: {
                watt: 12,
                replacement_cost: 10,
                price_per_tube: 53.00,
                rental_cost: 1.35, // eur
            },
            len105: {
                watt: 14,
                replacement_cost: 10,
                price_per_tube: 53.00,
                rental_cost: 1.35, // eur
            },
            len115: {
                watt: 14,
                replacement_cost: 10,
                price_per_tube: 53.00,
                rental_cost: 1.35, // eur
            },
            len120: {
                watt: 14,
                replacement_cost: 10,
                price_per_tube: 53.00,
                rental_cost: 1.35, // eur
            },
            len145: {
                watt: 18,
                replacement_cost: 10,
                price_per_tube: 59.90,
                rental_cost: 1.65, // eur / month
            },
            len150: {
                watt: 18,
                replacement_cost: 10,
                price_per_tube: 59.90,
                rental_cost: 1.65, // eur / month
            },
            len180: {
                watt: 18,
                replacement_cost: 10,
                price_per_tube: 69.90,
                rental_cost: 1.95, // eur / month
            },
            lifespan_h: 50000,
        },
    };
};

// functions object
// used to eliminate redundancy between function calls, both used between the diagramm and the table
Calculator.prototype.functions = function () {
    return {
        kwh: {
            name: 'KWH',
            funcDiagr: this._kwh,
            datasets: ['EVG', 'KVG', 'SMD_BUY_RENT'],
            funcTable: this._c_kwh,
            unit: 'kWh',
        },
        price_energy: {
            name: 'Price Energy',
            funcDiagr: this._energyCost,
            datasets: ['EVG', 'KVG', 'SMD_BUY_RENT'],
            funcTable: this._c_energyCost,
            unit: this.currData()[this.currency].sign,
        },
        number_of_replacements: {
            name: 'Number of Replacements',
            funcDiagr() {},
            datasets: [],
            funcTable: this._numberOfReplacements,
        },
        replacement_cost: {
            name: 'Replacement Costs',
            funcDiagr() {},
            datasets: [],
            funcTable: this._replacementCost,
        },
        buy_cost: {
            name: 'Buy Costs',
            funcDiagr() {},
            datasets: [],
            funcTable: this._buyCost,
        },
        rental_cost: {
            name: 'Rent Costs',
            funcDiagr() {},
            datasets: [],
            funcTable: this._rentCost,
        },
        price_overall: {
            name: 'Price Overall',
            funcDiagr: this._totalCost,
            datasets: ['EVG', 'KVG', 'SMD_BUY', 'SMD_RENT'],
            funcTable: this._c_totalCost,
            unit: this.currData()[this.currency].sign,
        },
        co2_emission: {
            name: 'CO2 Emission',
            funcDiagr: this._co2Emission,
            datasets: ['EVG', 'KVG', 'SMD_BUY_RENT'],
            funcTable() {},
            unit: 'kg',
        },
    };
};

// curData object
// contains data about currencies, exchange rates and energy price by currency
Calculator.prototype.currData = function () {
    return {
        eur: {
            name: 'EUR',
            energyPrice: 0.50.toFixed(2),
            sign: 'EUR',
        },
        chf: {
            name: 'CHF',
            exRate: 1.15,
            energyPrice: 0.25.toFixed(2),
            sign: 'CHF',
        },
        sek: {
            name: 'SEK',
            exRate: 10.9,
            energyPrice: 5.00.toFixed(2),
            sign: 'SEK',
        },
        nok: {
            name: 'NOK',
            exRate: 11.77,
            energyPrice: 5.00.toFixed(2),
            sign: 'NOK',
        },
    };
};

// validate object
// sets validators for form inputfields and generates error messages
Calculator.prototype.validate = function () {
    const self = this;
    const validator = new FormValidator('data', [{
        name: 'amount',
        rules: 'required|numeric|greater_than[0]',
    }, {
        name: 'energyprice',
        display: "'energy price'",
        rules: 'required|decimal|greater_than[0]',
    }, {
        name: 'weeksayear',
        display: "'weeks a year'",
        rules: 'required|numeric|greater_than[0]|less_than[53]',
    }, {
        name: 'daysaweek',
        display: "'days a week'",
        rules: 'required|numeric|greater_than[0]|less_than[8]',
    }, {
        name: 'hoursaday',
        display: "'hours a day'",
        rules: 'required|numeric|greater_than[0]|less_than[25]',
    }], ((errors, evt) => {
        const errClass = 'has-error';
        const errorDiv = $('#validation-errors');

        $('#data .form-group').each(function (index) {
            $(this).removeClass(errClass);
        });

        if (errors.length > 0) {
            let out = '';
            $.each(errors, (index, next) => {
                $(`#data input[name=${next.name}]`).parents('.form-group').addClass(errClass);
                out += `${next.message}<br>`;
            });
            $(errorDiv).html(out);
            $(errorDiv).show();
            self.FORM_ERRORS = true;
        } else {
            $(errorDiv).hide();
            self.FORM_ERRORS = false;
        }

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        } else if (event) {
            event.returnValue = false;
        }
    }));
};

// calculate function
// gets data from form fields and populates diagram and table
Calculator.prototype.calculate = function () {

    if (this.FORM_ERRORS) return false; // validation failed

    this.hoursPerYear = $('#weeksayear').val() * $('#daysaweek').val() * $('#hoursaday').val();
    this.numberOfTubes = $('#amount').val();
    this.len = $("#length input[type='radio']:checked").attr('value');
    this.currency = $("#currency input[type='radio']:checked").attr('value').toLowerCase();
    this.energyPrice = $('#energyprice').val();

    const funcId = $("#category").val();
    const func = this.functions()[funcId].funcDiagr;
    const { datasets } = this.functions()[funcId];
    const { unit } = this.functions()[funcId];

    if (this.totalSavings()) {
        this._drawDiagramm(func, datasets, unit);
        this.calcTable();
        $('#cta-placeholder').css('display', 'block');
    }
};

// fill in values in desktop and mobile table
Calculator.prototype.calcTable = function () {
    const self = this;
    const obsPeriod = this.OBS_PERIOD;

    // due to different markup for viewports we need to target different html structures
    const selectors = {
        desktop: '#calc-table-desktop tr td:nth-child(n+2)',
        mobile: '.calc-table-mobile .table__data'
    }

    for (selector in selectors) {
        // fill table where data-calc attr is present
        $('#facts__cb').show();
        $('#facts__ecg').hide();
        var is_kvg = false;
        $(selectors[selector]).each(function (index) {
            if ($(this).data('calc') !== undefined) {
                const options = $(this).data('calc');
                const calcFunc = self.functions()[options.func].funcTable;
                let out = calcFunc.apply(self, [self.data()[options.type], obsPeriod, options.rent, options.addReplacementCost]);

                if (options.curr) { // handle currency
                    out = options.conv ? self._convert(out) : out; // convert currency
                    if (isNaN(out)) {
                        out = "-";
                        $('#facts__cb').hide();
                        $('#facts__ecg').show();
                        is_kvg = true;
                    } else {
                        out = out.format(0, 3, self.currData()[self.currency].sign, true);
                    }
                } else if (options.func == "number_of_replacements"){
                    console.log(is_kvg);
                    if ($(this).hasClass('kvg-replacements') && is_kvg === true) {
                        console.log('-');
                        out = "-";
                        is_kvg = false
                    }
                    else {
                        out = out.format(1, 3);
                    }
                } else {
                    if (isNaN(out)) {
                        out = "-";
                    } else {
                        out = out.format(0, 3);
                    }
                }

                $(this).html(out);
            } else if ($(this).hasClass('zero-curr')) { // fields which are always zero + currency sign
                $(this).html(`-`);
                // $(this).html(`0 ${self.currData()[self.currency].sign}`);
            }
        });
    }
};

// calculate total savings
Calculator.prototype.totalSavings = function () {
    const ele = $('#total-savings');
    const costKVG = this._c_totalCost(this.data().kvg, this.OBS_PERIOD);
    const costSMD = this._c_totalCost(this.data().smd, this.OBS_PERIOD);
    const costEVG = this._c_totalCost(this.data().evg, this.OBS_PERIOD);
    if (costKVG) {
        var savings = costKVG - costSMD;
    } else {
        var savings = costEVG - costSMD;
    }
    const hasSavings = savings >= this.SAVINGS_LIMIT;
    if (hasSavings) {
        $('#no-savings-text').hide();
        $('#calc-details, #savings-text, .print-area').show();
    } else { // no savings
        $('#no-savings-text').show();
        $('#savings-text, #calc-details, .print-area').hide();
    }
    $('#savings-message').show();
    $(ele).html(savings.format(0, 3, this.currData()[this.currency].sign, true)); // no converting!
    return hasSavings;
};

Calculator.prototype._kwh = function (typeData, years) {
    const out = [];
    for (let i = 0; i <= years; i++) {
        out.push(this._round(this._c_kwh(typeData, i)));
    }
    return out;
};

Calculator.prototype._c_kwh = function (typeData, year) {
    return year * this.hoursPerYear * this.numberOfTubes * typeData[this.len].watt / 1000;
};

Calculator.prototype._totalCost = function (typeData, years, renting) {
    const out = [];
    for (let i = 0; i <= years; i++) {
        out.push(this._round(this._c_totalCost(typeData, i, renting))); // converting done in _c_totalCost
    }
    return out;
};

// calculate total overall cost
Calculator.prototype._c_totalCost = function (typeData, year, renting) {
    let val = 0;
    val += this._c_energyCost(typeData, year); // no converting!
    val += this._convert(this._replacementCost(typeData, year, renting));
    val += this._convert(this._buyCost(typeData, year, renting));
    if (renting) {
        val += this._convert(this._rentCost(typeData, year));
    }
    return val;
};

// calculate total replacement cost based on number of replacements and tubes
Calculator.prototype._replacementCost = function (typeData, year, renting) {
    const factor = this._numberOfReplacements(typeData, year, renting) * this.numberOfTubes;
    return factor * typeData[this.len].replacement_cost;
};

// calculate total buy cost based on number of replacements and tubes
Calculator.prototype._buyCost = function (typeData, year, renting) {
    const factor = this._numberOfReplacements(typeData, year, renting) * this.numberOfTubes;
    return factor * typeData[this.len].price_per_tube;
};

// calculate replacements + one time buy
Calculator.prototype._numberOfReplacements = function (typeData, years, renting) {
    let val = renting ? 0 : 1;
    val += this.hoursPerYear * years / typeData.lifespan_h;
    return val;
};

// calculate energy cost by year
Calculator.prototype._energyCost = function (typeData, years) {
    const out = [];
    for (let i = 0; i <= years; i++) {
        // do NOT convert energy costs, energy price is entered by user for specified currency
        out.push(this._round(this._c_energyCost(typeData, i)));
    }
    return out;
};

Calculator.prototype._c_energyCost = function (typeData, year) {
    let val = 0;
    for (let i = 0; i < year; i++) {
        val += this._c_once_energyCost(typeData, i);
    }
    return val;
};

Calculator.prototype._c_once_energyCost = function (typeData, year) {
    return this.hoursPerYear * this.numberOfTubes * typeData[this.len].watt / 1000 * Math.pow(this.YEARLY_ENERGY_PRICE_INCREASE, year) * this.energyPrice;
};

Calculator.prototype._co2Emission = function (typeData, years) {
    const out = [];
    for (let i = 0; i <= years; i++) {
        out.push(this._round(i * this.hoursPerYear * this.numberOfTubes * typeData[this.len].watt / 1000 * 0.59));
    }
    return out;
};

Calculator.prototype._rentCost = function (typeData, years, renting, addReplacementCost) {
    /*
    * If we fill out the table, we add replacement costs and buy costs to the
    * rent costs, like we did in _c_totalCost()
    */
    let additionalCosts = 0;
    if (addReplacementCost) {
        additionalCosts += this._replacementCost(typeData, years, true);
        additionalCosts += this._buyCost(typeData, years, true);
    }
    return typeData[this.len].rental_cost * 12 * years * this.numberOfTubes + additionalCosts;
};

// round to 2 decimal places
Calculator.prototype._round = function (num) {
    return (Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2);
};

// return number in current currency ecxange rate
Calculator.prototype._convert = function (num) {
    if (this.currency !== 'eur') {
        return num * this.currData()[this.currency].exRate;
    }
    return num;
};

// return number in current currency ecxange rate
Calculator.prototype.setSize = function (width) {
    var canvas = $('#canvas');
    if (window.innerWidth < 640) {
        canvas.css('height', width / 1.27);
    } else {
        canvas.css('height', width / 2);
    }

    if (typeof myLine !== 'undefined') {
        myLine.resize();
    }
};

// Defines line datapoints and styling
Calculator.prototype._drawDiagramm = function (calcFunc, datasets, unit) {

    Chart.defaults.global.elements.point.radius = 5;
    Chart.defaults.global.elements.point.borderWidth = 1.5;
    Chart.defaults.global.elements.point.hoverRadius = 5;
    Chart.defaults.global.elements.point.hoverBorderWidth = 1.5;
    Chart.defaults.global.elements.point.hoverBorderColor = '#fff';

    Chart.defaults.global.elements.line.backgroundColor = 'transparent';

    const colorKVG = '#CC3D3D';
    const colorEVG = '#F9AD22';
    const colorSMDR = '#84C618';
    const colorSMDB = '#108E3C';

    const lineChartData = {
        labels: [gettext('0'), gettext('1'), gettext('2'), gettext('3'), gettext('4')],
        datasets: [
            {
                label: gettext('CB'),
                data: calcFunc.apply(this, [this.data().kvg, this.OBS_PERIOD]),
                pointBackgroundColor: colorKVG,
                pointHoverBackgroundColor: colorKVG,
                borderColor: colorKVG,
                pointBorderColor: 'white',
            }, {
                label: gettext('ECG'),
                data: calcFunc.apply(this, [this.data().evg, this.OBS_PERIOD]),
                pointBackgroundColor: colorEVG,
                pointHoverBackgroundColor: colorEVG,
                borderColor: colorEVG,
                pointBorderColor: 'white',
            },
        ],
    };

    if ($.inArray('SMD_BUY_RENT', datasets) > 0) {
        lineChartData.datasets.push({
            label: gettext('GLT TUBE rent / purchase'),
            data: calcFunc.apply(this, [this.data().smd, this.OBS_PERIOD]),
            pointBackgroundColor: colorSMDB,
            borderColor: colorSMDR,
            pointHoverBackgroundColor: colorSMDB,
            pointBorderColor: 'white',
        });
    } else {
        if ($.inArray('SMD_BUY', datasets) > 0) {
            lineChartData.datasets.push({
                label: gettext('GLT TUBE purchase'),
                data: calcFunc.apply(this, [this.data().smd, this.OBS_PERIOD]),
                pointBackgroundColor: colorSMDB,
                pointHoverBackgroundColor: colorSMDB,
                borderColor: colorSMDB,
                pointBorderColor: 'white',
            });
        }

        if ($.inArray('SMD_RENT', datasets) > 0) {
            lineChartData.datasets.push({
                label: gettext('GLT TUBE rent'),
                data: calcFunc.apply(this, [this.data().smd, this.OBS_PERIOD, true]),
                pointBackgroundColor: colorSMDR,
                pointHoverBackgroundColor: colorSMDR,
                borderColor: colorSMDR,
                pointBorderColor: 'white',
            });
        }
    }

    if (window.myLine) window.myLine.destroy(); // clear old char data

    const ctx = document.getElementById('canvas').getContext('2d');

    window.myLine = new Chart(ctx, {
        type: 'line',
        data: lineChartData,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scaleLabel: `<%= value.replace(/./g,function(c,i,a){return i&&c!=='.'&&!((a.length-i)%3)?' '+c:c;}) + ' ${unit}' %>`,
            tooltips: false,
            scales: {
                yAxes: [{
                    gridLines: {
                        color: '#ccc',
                        zeroLineColor: '#ccc'
                    },
                    ticks: {
                        callback: function(value, index, values) {
                            // if (value >= 1000000) {
                            //     return (value / 1000000).format() + ' Mio';
                            // }
                            return value.format();
                        },
                        padding: 5,
                    },
                    position: 'right',
                    scaleLabel: {
                        display: true,
                        labelString: unit,
                        fontColor: '#999',
                    }
                }],
                xAxes: [{
                    gridLines: {
                        color: '#ccc',
                    },
                    scaleLabel: {
                        display: true,
                        labelString: gettext('Years'),
                        fontColor: '#999',
                    }
                }]
            },
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                },
            },
            legendCallback: function (t) {
                var e = [];
                e.push('<ul class="' + t.id + '-legend">');
                for (var i = 0; i < t.data.datasets.length; i++) e.push('<li><span style="background-color:' + t.data.datasets[i].borderColor + '"></span>'), t.data.datasets[i].label && e.push(t.data.datasets[i].label), e.push("</li>");
                return e.push("</ul>"), e.join("")
            },
            onResize: function (i, s) {
                Calculator.prototype.setSize(s.width);
            },
        }
    });
    $('#calc-legend').html(myLine.generateLegend());

    this.setSize($('#canvas').width());
};

$(document).ready(() => {
    const calcInit = document.querySelector('.js-init-calculator');

    if (calcInit) {
        const calc = new Calculator();
        calc.validate();

        $('#currency').change(() => {
            const currentCurrency = $("#currency input[type='radio']:checked").attr('value');
            $('.currency-label').replaceWith(`<span class="inputs__prefix currency-label">${currentCurrency}</span>`);
            $('#energyprice').val(calc.currData()[currentCurrency.toLowerCase()].energyPrice);
        });

        $('#data').submit(() => {
            if (typeof ga !== 'undefined') {
                ga('send', 'event', 'form', 'submit', 'calculator');
            }

            calc.calculate();
            // $(window).scrollTop($('#calc-details').offset().top);
        });
        $('#func').change(() => {
            // $('#data').triggerHandler('submit'); // we need validation context here
            calc.calculate();
        });
    }
});
