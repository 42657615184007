function initReferences() {
    const savingsEl = $('[data-power-savings]');
    const savingsYear = Number(savingsEl.attr('data-power-savings'));
    const currentYear = new Date().getFullYear();
    const firstDayOfCurrentYear = new Date(currentYear, 0, 1, 0, 0, 0, 0).getTime() / 1000;
    const currentTime = new Date().getTime() / 1000;
    const elapsedSeconds = currentTime - firstDayOfCurrentYear;
    const lastSecondOfCurrentYear = Date.parse('January 01,' + (currentYear + 1)) / 1000;
    const lengthOfCurrentYear = lastSecondOfCurrentYear - firstDayOfCurrentYear;
    const percentageOfCurrentYear = elapsedSeconds / lengthOfCurrentYear * 100;
    const savingsOfCurrentYear = savingsYear / 100 * percentageOfCurrentYear;
    const energyPricePerKwhEUR = 0.17;
    const energyPricePerKwhSEK = 1.00;
    const incrementSpeed = 125;
    const language = document.documentElement.lang;
    const formatter = new Intl.NumberFormat(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let counterValue = savingsOfCurrentYear;
    let energyPricePerKwh = 0.00;

    if (language === 'sv') {
        energyPricePerKwh = energyPricePerKwhSEK;
    } else {
        energyPricePerKwh = energyPricePerKwhEUR;
    }

    $('.js-energy-price-kwh').html(formatter.format(energyPricePerKwh));

    setInterval(function() {
        let counterSpeed = 1000 / incrementSpeed;
        counterValue += (savingsYear / lengthOfCurrentYear / counterSpeed);

        savingsEl.html(formatter.format(counterValue));
        $('.js-price-saved').html(formatter.format(counterValue * energyPricePerKwh));
    }, incrementSpeed);
}

$(() => {
    initReferences();

    // custom event
    $(window).on('initReferences', initReferences);

    // re-init after cms page refresh
    if (window.CMS) {
        CMS.$(window).on('cms-content-refresh', initReferences);
    }
});
